
.soft-wrap {
    margin-top: 18px;
    height: calc(100% - 62px);
    display: flex;
    flex-direction: column;
    padding: 0 20px 44px;

    .main-content {
        height: 100%;
        display: flex;
        flex-direction: column;

        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;

            .el-scrollbar__view {
                display: flex;
                flex-direction: column;
                height: 100%;
                padding: 0 20px;
            }
        }
    }

    .chat-box {
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #DCDCDC;
        border-radius: 4px;
        position: relative;
        display: flex;
        align-items: center;
        margin-left: 10px;

        .chat-content {
            margin: 0 10px;

            .red-text {
                color: #FF0000;
            }
        }

        .angleLeft {
            position: absolute;
            border: 6px solid transparent;
            top: 18px;
            margin-top: -8px;
            left: -14px;
            border-right: 8px solid #ededed;
        }

        .angleContent {
            position: absolute;
            border: 4px solid transparent;
            top: 50%;
            margin-top: -4px;
            z-index: 99;
            left: -1.5px;
            border-right: 6px solid #ffffff;
        }
    }

    .item-title {
        font-size: 18px;
        position: relative;

        &:before {
            content: '';
            width: 5px;
            height: 20px;
            background: #2DC079;
            border-radius: 3px;
            position: absolute;
            left: -13px;
            top: 3px;
        }
    }

    .head-wrap {
        padding: 34px 100px 26px 44px;

        .head-content {
            display: flex;
            align-items: center;
        }

        .item-content {
            margin-top: 24px;
        }
    }

    .top-box {
        display: flex;

        .top-left {
            border-radius: 8px;
            border: 1px solid #eeeeee;

            .item-content {
                width: 260px;
                height: 260px;
            }
        }

        .top-right {
            margin-left: 34px;
            border-radius: 8px;
            border: 1px solid #eeeeee;
            flex: 1;

            .item-content {
                width: 100%;
                height: 260px;
            }
        }
    }

    .table-box {
        border-radius: 8px;
        border: 1px solid #eeeeee;
        margin-top: 24px;

        .item-content {
            padding: 0 28px 40px;
        }

        .deal-box {
            display: flex;
            align-items: center;

            .el-progress {
                width: 220px;
                margin-left: 15px;
            }
        }
    }
}
